import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { CRYPTO_JS_SECRET } from "../../utils/constants";
import { checkToken, getRole } from "../../utils/services";
import CryptoJS from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import { GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID } from "../../queries/vip-workflow";
import { useApolloClient } from "@apollo/client";
import { GET_BROKER_PROFILE, GET_PROFILE } from "../../queries/auth";
import React from "react";

const Footer = () => {
  const client = useApolloClient();
  const { pathname } = useLocation();

  const [userEmail, setUserEmail] = React.useState("");
  const [invoiceInfo, setInvoiceInfo] = React.useState("");
  const [invoiceId, setInvoiceId] = React.useState("");

  React.useEffect(() => {
    getUserData();
    getPackageInfoForSupportEmail(pathname);
  }, []);

  const getPackageInfoForSupportEmail = async (pathname: string) => {
    if (pathname.match("/package-info")) {
      const slashesReturnedToEncryptedInvoiceId = pathname
        .replace(/HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61/g, "/")
        .replace("/package-info/", "");

      const decryptedInvoiceId = Number.parseFloat(
        CryptoJS.decrypt(slashesReturnedToEncryptedInvoiceId.toString(), CRYPTO_JS_SECRET).toString(
          enc,
        ),
      );

      if (Number.isNaN(decryptedInvoiceId)) return;

      const { data } = await client.query({
        query: GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
        variables: { invoiceId: decryptedInvoiceId, orderDetailsFilter: "" },
        fetchPolicy: "cache-first",
      });

      setInvoiceInfo(
        `%0D%0A Event Name: ${data?.getOnePackageWithDetailsForClient.eventName} %0D%0A Event Date: ${data?.getOnePackageWithDetailsForClient.eventDate} %0D%0A Invoice ID: ${data?.getOnePackageWithDetailsForClient.invoiceId} %0D%0A Invoice link: https://app.dev.elevatevip.live${pathname}`,
      );

      setInvoiceId(data?.getOnePackageWithDetailsForClient.invoiceId);
    }
  };

  const getUserData = async () => {
    if (checkToken()) {
      if (getRole() === "RECIPIENT") {
        const data = await client.readQuery({
          query: GET_PROFILE,
        });

        setUserEmail(data?.getProfile.email);
      }

      if (getRole() === "BROKER") {
        const data = await client.readQuery({
          query: GET_BROKER_PROFILE,
        });

        setUserEmail(data?.getProfile.email);
      }
    }
  };

  const FEEDBACK_MAIL_BODY = `%0D%0A
    %0D%0A
    %0D%0A ---------------
    %0D%0A
    %0D%0A PLEASE WRITE YOUR MESSAGE ABOVE THIS LINE AND LEAVE EVERYTHING BELOW
    %0D%0A
    %0D%0A Elevate VIP Feedback
    ${userEmail ? `%0D%0A %0D%0A User: ${userEmail}` : ""}
    ${invoiceInfo}
`;

  const FEEDBACK_MAIL_SUBJECT = invoiceId
    ? `Elevate VIP Feedback: Invoice ${invoiceId}`
    : "Elevate VIP Feedback";

  const SUPPORT_MAIL = "support@premiumseatsusa.zendesk.com";

  return (
    <Container fluid color="default" className="bg-default p-1">
      <footer className="p-3">
        <Row className="align-items-baseline justify-content-center">
          <h2 className="text-white pl-3 pr-3">Need some help or got a question?</h2>
          <a
            className="btn btn-md btn-secondary"
            href={`mailto:${SUPPORT_MAIL}?subject=${FEEDBACK_MAIL_SUBJECT}&body=${FEEDBACK_MAIL_BODY}`}
          >
            Get in touch
          </a>
        </Row>
      </footer>
    </Container>
  );
};

export default Footer;
